const Error = ({error}) => {
    if (null == error) {
        return null;
    }

    return (
        <div className="form-error fade-in-bottom">
            <p>{error}</p>
        </div>
    );
};

const TextInput = ({
  id,
  error,
  required,
  label,
  labelClass,
  help,
  value,
  ErrorComponent = Error,
  ...restProps
}) => {
  return (
    <>
      <label htmlFor={id} className={(labelClass ?? 'form-label') + (required ? (' required') : '')}>
        {label}
      </label>
      <input
        id={id}
        className="default-input"
        value={value || ''}
        {...restProps}
      />
      <span className="small">{help}</span>
      <ErrorComponent {...{error}} />
    </>
  )
};

export default TextInput;
