import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import TextInput from './TextInput';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { AWS_ERROR_DISABLED_USER, AWS_ERROR_USER_DOES_NOT_EXIST, AWS_ERROR_USER_NOT_FOUND, FAILURE, LOADING, SUCCESS } from '../constants';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email.')
    .max(60, 'The email is too long.')
    .required('Please enter an email.'),
});

const awsError = (awsErrorMessage) => {
  switch (awsErrorMessage) {
    case AWS_ERROR_DISABLED_USER:
      return (
        <>
          Your account needs to be validated before you can reset your password. Please contact the Administrator for your school or college, or <a href="mailto:compassplus@careersandenterprise.atlassian.net" className="link-red-to-gray">contact us</a> if you do not know your Administrator.
        </>
      );
    case AWS_ERROR_USER_DOES_NOT_EXIST:
    case AWS_ERROR_USER_NOT_FOUND:
      return (
        <>
          Your email is not registered. Please contact the Administrator for your school or college, or <a href="mailto:compassplus@careersandenterprise.atlassian.net" className="link-red-to-gray">contact us</a> if you do not know your Administrator.
        </>
      );
    default:
      return awsErrorMessage;
  }
};

const ForgottenPasswordModal = ({email, isOpen, toggle, cognitoClientMetadata}) => {
  const [awsErrorMessage, setAwsErrorMessage] = useState();
  const [callState, setCallState] = useState(null);

  useEffect(() => {
    return () => {
      cleanAll();
    };
  }, []);

  const cleanAll = () => {
    cleanErrors();

    setCallState(null);
  };

  const cleanErrors = () => {
    setAwsErrorMessage(null);
  };

  return (
    <Modal
      className="default-modal"
      centered
      isOpen={isOpen}
      toggle={() => toggle()}
    >
      <Formik
        validationSchema={VALIDATION_SCHEMA}
        initialValues={{
          email: email ?? '',
        }}
        onSubmit={(values) => {
          cleanErrors();

          setCallState(LOADING);

          Auth.forgotPassword(values.email, cognitoClientMetadata)
            .then(() => setCallState(SUCCESS))
            .catch((error) => {
              setCallState(FAILURE);
              setAwsErrorMessage(error.message);
            });
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({getFieldProps, errors, handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader
              toggle={() => toggle()}
            >
              Reset your password
            </ModalHeader>
            <ModalBody>
              <p className="text-start">
                Enter your email address below and we will send you an email with a link to reset your password.
              </p>
              {/*Email*/}
              <div className="default-form text-start">
                <div className="form-group row">
                  <div className="form-block col-12 position-relative">
                    <TextInput
                      {...getFieldProps('email')}
                      id="reset-email"
                      placeholder="Email address"
                      label="Your email"
                      error={errors.email}
                      required
                    />
                    <i className="mdi mdi-email-outline input-icon" />
                  </div>
                </div>
                {SUCCESS === callState || awsErrorMessage === AWS_ERROR_USER_DOES_NOT_EXIST || awsErrorMessage === AWS_ERROR_USER_NOT_FOUND ? (
                  <div className="alert alert-success">
                    If an account with that email address exists,
                    you will receive a link to reset your password.
                    This verification code is valid for 1 hour and then it will expire.
                  </div>
                ) : (
                  <>
                    {null != awsErrorMessage && (
                      <div className="alert alert-cec alert-cec-warning text-start fade-in-bottom">
                        {awsError(awsErrorMessage)}
                      </div>
                    )}
                  </>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="cec-btn cec-btn-secondary-teal"
                data-cy="reset-password-close--modal"
                onClick={() => toggle()}
              >
                Close
              </button>
              <button
                type="submit"
                className="cec-btn cec-btn-primary-teal"
                data-cy="reset-password-submit--modal"
                disabled={LOADING === callState || SUCCESS === callState || AWS_ERROR_USER_DOES_NOT_EXIST === awsErrorMessage || AWS_ERROR_USER_NOT_FOUND === awsErrorMessage}
              >
                {LOADING !== callState ? 'Send' : 'Sending...'}
              </button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default ForgottenPasswordModal;
