import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from 'yup';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import TextInput from "./TextInput";
import {FAILURE, LOADING, SUCCESS} from "../constants";

import * as api from '../api';

const INITIAL_VALUES = {
  code: '',
}

const VALIDATION_SCHEMA = Yup.object().shape({
  code: Yup.string()
    .matches(/^\d+$/, 'The code should have digits only.')
    .min(6, 'The code is too short.')
    .max(6, 'The code is too long.')
    .required('Please enter your code.'),
});

const VerifyPhoneNumberModal = ({ isOpen, authCode, baseURL, redirectTo }) => {

  const history = useHistory();

  const [awsErrorMessage, setAwsErrorMessage] = useState();
  const [callState, setCallState] = useState(null);

  const cleanErrors = () => {
    setAwsErrorMessage(null);
  };

  if (!authCode) {
    return <></>;
  }

  return (
    <Modal
      className="default-modal"
      centered
      isOpen={isOpen}
    >
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values) => {
          cleanErrors();
          setCallState(LOADING);

          api.postVerifyPhoneNumber(
            authCode,
            values.code,
          )
            .then(() => {
              history.push(redirectTo);
            })
            .catch((error) => {
              setCallState(FAILURE);
              setAwsErrorMessage(error.message);
            });
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ getFieldProps, errors, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader>
              Verify your phone number
            </ModalHeader>
            <ModalBody>
              <p className="text-start">
                Enter the verification code you have received in your phone.
              </p>
              <div className="default-form text-start">
                <div className="form-group row">
                  <div className="form-block col-12 position-relative">
                    <TextInput
                      {...getFieldProps('code')}
                      id="code"
                      placeholder="- - - - - -"
                      label="Your code"
                      error={errors.code}
                      required
                    />
                    <i className="mdi mdi-lock-clock input-icon" />
                  </div>
                </div>
              </div>
              {null != awsErrorMessage && (
                <div className="alert alert-cec alert-cec-warning text-start fade-in-bottom">
                  {awsErrorMessage}
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <button
                type="submit"
                className="cec-btn cec-btn-primary-teal"
                disabled={LOADING === callState || SUCCESS === callState}
              >
                {LOADING !== callState ? 'Verify' : 'Verifying...'}
              </button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

export default VerifyPhoneNumberModal;
