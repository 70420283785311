import moment from 'moment';
import cecLogo from '../../images/cec-logo.svg';

const Footer = () => {
  const cecWebUrl = process.env.REACT_APP_CEC_WEB_URL;

  return (
    <footer id="default-footer" className="login-footer">
      <div className="footer-container container py-5 px-0">
        <div className="my-auto">
          <a href={cecWebUrl} target="_blank" rel="noopener noreferrer">
            <img
              width="100"
              src={cecLogo}
              alt="The Careers and Enterprise Company"
              title="The Careers and Enterprise Company"
            />
          </a>
        </div>
        <div className="ms-auto my-auto font-14">
          © The Careers and Enterprise Company {moment().format('Y')}.
          All rights reserved.
          <a
            href={cecWebUrl + "/privacy-notice/"}
            className="link-teal-to-gray"
            target="_blank"
            rel="noopener noreferrer">
            CEC privacy notice
          </a> |
          <a
            href={cecWebUrl + "/terms-and-conditions-evaluation-tools"}
            className="link-teal-to-gray"
            target="_blank"
            rel="noopener noreferrer">
            Terms and Conditions
          </a>.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
