import cecLogoWhite from '../../images/cec-logo-white.png';
import {Amplify} from 'aws-amplify';
import awsExports from '../../aws-exports';
import awsExportsEanr from '../../aws-exports-eanr';

const Header = () => {
  Amplify.configure(Object.assign(awsExports, awsExportsEanr)); //Merge aws-exports, the latest will override the first

  return (
    <header id="eanr-header" className="bg-purple shadow {% if 'login' is same as(route) %}mb-auto{% endif %}">
      <nav className="container py-3">
        <div className="logo">
          <img src={cecLogoWhite} alt="The Careers & Enterprise Company" title="The Careers & Enterprise Company" />
        </div>
        <div className="buttons">
          <a href={process.env.REACT_APP_SCHOOLSHUB_URL} className="cec-btn cec-btn-primary-teal cec-btn-primary-color-blind link-as-button me-2">Tools</a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
