import React, { useEffect, useState } from 'react';
import TextInput from '../../components/TextInput';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import * as api from '../../api';
import { PATH_SCHOOLSHUB } from '../../Router';
import ForgottenPasswordModal from '../../components/ForgottenPasswordModal';
import { APP_SCHOOLSHUB, MFA_TYPE } from '../../constants';
import Error from '../../components/Error';
import AskMFAModal from '../../components/mfa/modals/AskMFAModal';
import Layout from '../../components/schoolshub/Layout';
import { sendMFASelectionAnswer } from '../../components/Utils';

const TITLE = 'Schools Hub - Log in';
const COGNITO_CLIENT_METADATA = { app: APP_SCHOOLSHUB };
const PASSWORD_RESET_EMAIL_COGNITO_CLIENT_METADATA = { ...COGNITO_CLIENT_METADATA, action: 'resetPassword' };

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Please enter an email.'),
  password: Yup.string()
    .required('Please enter a password.'),
});

const Login = () => {
  const [ isLoggingIn, setIsLoggingIn ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState();
  const [ awsErrorMessage, setAwsErrorMessage ] = useState();
  const [ isOpenForgottenPasswordModal, setIsOpenForgottenPasswordModal ] = useState(false);
  const [ forgottenPasswordEmail, setForgottenPasswordEmail ] = useState();
  const [ successMessage, setSuccessMessage ] = useState(null);

  const [ isMfaModalOpen, setMfaModalOpen ] = useState(false);
  const [ cognitoResponse, setCognitoResponse ] = useState(null);
  const [ userCredentials, setUserCredentials ] = useState([]);

  const handleError = (error) => {
    setErrorMessage(error.response?.data?.error ?? error.response?.data?.detail ?? 'Oops! Something went wrong.');
    setIsLoggingIn(false);
  }

  const handleLogin = (forcedCognitoResponse = null) => {
    const {
      idToken,
      refreshToken,
      accessToken,
    } = (forcedCognitoResponse ?? cognitoResponse).signInUserSession;

    api.loginCompassClassic(idToken.jwtToken, refreshToken.token)
      .then((response) => {
        const extraLogins = [];

        if (response.data.canAccessCareersPartners) {
          extraLogins.push(api.loginCareersPartners(idToken.jwtToken, refreshToken.token));
        }

        if (response.data.canAccessCLTP) {
          extraLogins.push(api.loginCltp(idToken.jwtToken, refreshToken.token));
        }

        if (response.data.canAccessCompassPlus) {
            extraLogins.push(api.loginCompassPlus(idToken.jwtToken, refreshToken.token, accessToken.jwtToken, true));
        }

        return Promise.all(extraLogins);
      })
      .then(() => window.location = process.env.REACT_APP_SCHOOLSHUB_URL + '/dashboard')
      .catch((error) => handleError(error))
    ;
  };

  useEffect(() => {
    document.title = TITLE;

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    const logoutParam = searchParams.get('logout');

    if (null != logoutParam) {
      window.history.replaceState({}, document.title, PATH_SCHOOLSHUB);

      Auth.signOut({ global: true })
        .then(() => {/* Do nothing */});

      localStorage.clear();

      return;
    }

    const resetParam = searchParams.get('reset');

    if (null != resetParam) {
      window.history.replaceState({}, document.title, PATH_SCHOOLSHUB);

      setIsOpenForgottenPasswordModal(true);
      setForgottenPasswordEmail(resetParam);

      return;
    }

    const resetPasswordSuccessParam = searchParams.get('reset-password-success');

    if (null != resetPasswordSuccessParam) {
      setSuccessMessage('Your password has been reset.');
    }

    const phoneNumberSetupParam = searchParams.get('phone-number-setup-success');

    if (null !== phoneNumberSetupParam) {
      setSuccessMessage('Your MFA has been updated.');
    }
  }, []);

  useEffect(() => {
    return () => {
      cleanAll();
    };
  }, []);

  const cleanAll = () => {
    cleanErrors();

    setIsLoggingIn(false);
  };

  const cleanErrors = () => {
    setAwsErrorMessage(null);
    setErrorMessage(null);
  };

  const showMFAModal = (values) => {
    setUserCredentials([values.email, values.password]);
    setMfaModalOpen(true);
  }

  const cecWebUrl = process.env.REACT_APP_CEC_WEB_URL;

  return (
    <>
      <ForgottenPasswordModal
        email={forgottenPasswordEmail}
        isOpen={isOpenForgottenPasswordModal}
        toggle={() => setIsOpenForgottenPasswordModal((prev) => !prev)}
        cognitoClientMetadata={PASSWORD_RESET_EMAIL_COGNITO_CLIENT_METADATA}
      />

      {isMfaModalOpen && (
        <AskMFAModal
          project={'Compass Plus'}
          toggle={() => setMfaModalOpen((prev) => !prev)}
          setCognitoUser={setCognitoResponse}
          cognitoUser={cognitoResponse}
          userCredentials={userCredentials}
          handleSuccess={() => handleLogin()}
          handleLoginAwsError={(errorMessage) => {
            setAwsErrorMessage(errorMessage);
            setIsLoggingIn(false);
            setMfaModalOpen(false);
          }}
          clientMetadata={COGNITO_CLIENT_METADATA}
        />
      )}

      <Layout>
        {null !== successMessage && (
          <div className="alert alert-success mb-5">
            {successMessage}
          </div>
        )}

        <div className="row">
          <div className="col-6 position-relative">
            <h1>Evaluation and planning tools</h1>

            <p>We have created Compass and Tracker, free online tools for schools and colleges in England.</p>

            <p>Compass helps you to evaluate your institution's careers activity against the eight benchmarks of best practice &mdash; known as the Gatsby Benchmarks.
            </p>
            <p>
              <a href={`${process.env.REACT_APP_COMPASS_CLASSIC_URL}/info`} className="btn">Find out more about Compass</a>
            </p>
            <p>Tracker is our careers planning tool. You can use this to record and manage your careers plan for the year.
            </p>
            <p>
              <a href={`${process.env.REACT_APP_TRACKER_CLASSIC_URL}/info`} className="btn">Find out more about Tracker</a>
            </p>

            <p>Please see our Digital tools guidance PDF for an overview about Compass and Tracker.
            </p>
            <p>
              <a
                href={`${process.env.REACT_APP_SCHOOLSHUB_URL}/docs/1139_Digitals Tool Guidance_Jan20.pdf`}
                className="btn"
                target="_blank"
              >
                Digital Tools Guidance
              </a>
            </p>
          </div>

          <div className="col-6 position-relative">
            <div className="box">
              <h2>Log in</h2>

              <div style={{ marginTop: '1rem'}}>
                Or <a href={`${process.env.REACT_APP_SCHOOLSHUB_URL}/register`}>create an account</a>
              </div>

              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validationSchema={VALIDATION_SCHEMA}
                onSubmit={(values) => {
                  setIsLoggingIn(true);
                  cleanErrors();

                  Auth.signIn(values.email, values.password, COGNITO_CLIENT_METADATA)
                    .then((cognitoResponse) => {
                      const type = cognitoResponse.challengeName;

                      if ([MFA_TYPE.APP, MFA_TYPE.EMAIL, MFA_TYPE.SMS].includes(type)) {
                        setCognitoResponse(cognitoResponse);

                        showMFAModal(values);

                        return;
                      }

                      if ( MFA_TYPE.SELECT === type ) {
                        cognitoResponse.challengeName = MFA_TYPE.SMS;
                        setCognitoResponse(cognitoResponse);
                        sendMFASelectionAnswer(MFA_TYPE.SMS, {
                          mfaRequired: (challengeName, challengeParameters) => {
                            showMFAModal(values);
                          },
                          totpRequired: (challengeName, challengeParameters) => {
                            showMFAModal(values);
                          }
                        }, cognitoResponse, COGNITO_CLIENT_METADATA);

                        return;
                      }

                      setCognitoResponse(cognitoResponse);

                      handleLogin(cognitoResponse);
                    })
                    .catch((error) => {
                      setAwsErrorMessage(error.message);
                      setIsLoggingIn(false);
                    });
                }}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({ getFieldProps, handleSubmit, errors }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <TextInput
                        {...getFieldProps('email')}
                        id={'email'}
                        label={'Your email address'}
                        autoComplete="email"
                        error={errors.email}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <TextInput
                        {...getFieldProps('password')}
                        id={'password'}
                        label={'Your password'}
                        autoComplete="password"
                        type="password"
                        error={errors.password}
                        required
                      />
                    </div>

                    {/* Message Errors */}
                    <Error
                      message={errorMessage ?? awsErrorMessage}
                      isAws={null == errorMessage && null != awsErrorMessage}
                      resetPasswordOnClick={() => setIsOpenForgottenPasswordModal(true)}
                    />

                    <div className="form-group txtr">
                      <p>
                        <button
                          id="login-button"
                          type="submit"
                          className="btn btn-grey mp-login-button"
                          disabled={isLoggingIn}
                        >
                          {!isLoggingIn ? 'Log in' : 'Logging in...'}
                        </button>
                      </p>

                      <p className="small">
                        <a
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();

                            setIsOpenForgottenPasswordModal(true);
                          }}
                        >
                          Forgotten your password?
                        </a>
                      </p>
                      {/* ISO27001 guidance */}
                      <p style={{textAlign: "right", marginTop: "1rem"}} className="small">
                        This website is for Authorised Users and is subject to our&nbsp;
                        <a href={cecWebUrl + "/careers-leaders/tools-resources/terms-and-conditions/"}>
                          Terms and Conditions.</a>
                      </p>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Login;
